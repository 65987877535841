<template>
  <div>
    <text-field :label="$t('pageBase.mapName')" v-model="name" type="text" class="map_name" />
    <double-inputs-block
      :data="data"
      :chartType="mapType"
      :secondMapShowPlaceInputs="mapType !== transitType"
      :submit-in-progress="submitInProgress"
      :secondMapBirthTimePlaceholder="$t('createMap.prognosisTime')"
      :secondMapBirthDatePlaceholder="$t('createMap.prognosisDate')"
      @submit="onSubmit"
    />
  </div>
</template>
<script>
import { ChartType } from 'astro-chart';
import DoubleInputsBlock from './DoubleInputsBlock';
import textField from '@/elements/components/TextField';

export default {
  name: 'Prognosys',
  components: { DoubleInputsBlock, textField },
  props: {
    mapType: {
      type: String,
      default: ChartType.SOLAR,
    },
    mapName: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transitType: ChartType.TRANSIT,
      name: this.mapName,
      mapTypes: {
        [ChartType.SOLAR]: {
          chartType: ChartType.SOLAR,
          label: 'Соляр',
        },
        [ChartType.TRANSIT]: {
          chartType: ChartType.TRANSIT,
          label: 'Транзит',
        },
        [ChartType.DIRECTION]: {
          chartType: ChartType.DIRECTION,
          label: 'Дирекция',
        },
        [ChartType.PROGRESSION]: {
          chartType: ChartType.PROGRESSION,
          label: 'Прогрессия',
        },
      },
      selectedMapType: this.mapType,
    };
  },
  methods: {
    async onSubmit({ map1, map2, chartType }) {
      if (chartType === this.transitType) {
        map2.birthPlace = map1.birthPlace;
        map2.birthLong = map1.birthLong;
        map2.birthLat = map1.birthLat;
      }

      this.$emit('submit', { map1, map2, chartType, mapName: this.name });
    },
  },
};
</script>
<style lang="sass">
.prognosys-map-types
  margin-top: base-unit(5)
  .v-messages
    display: none
  .v-input--radio-group__input
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr
    @media (min-width: 768px)
      display: flex
      flex-direction: row
      width: fit-content
      .v-radio
        margin-right: 30px
        &:last-child
          margin: 0 0 8px 0
</style>

<template>
  <div>
    <text-field :label="$t('pageBase.mapName')" v-model="name" type="text" class="map_name" />
    <double-inputs-block
      :chartType="chartType"
      :data="data"
      :firstMapTitle="$t('pageBase.partner') + ' ' + 1"
      :secondMapTitle="$t('pageBase.partner') + ' ' + 2"
      :submit-in-progress="submitInProgress"
      @submit="onSubmit"
    />
  </div>
</template>
<script>
import { ChartType } from 'astro-chart';
import DoubleInputsBlock from './DoubleInputsBlock';
import textField from '@/elements/components/TextField';

export default {
  name: 'SynastryForm',
  props: {
    mapName: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  components: { DoubleInputsBlock, textField },
  data() {
    return {
      name: this.mapName,
      chartType: ChartType.SYNASTRY,
    };
  },
  methods: {
    onSubmit(data) {
      this.$emit('submit', { ...data, mapName: this.name });
    },
  },
};
</script>

<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <text-field :label="$t('pageBase.mapName')" v-model="name" type="text" class="map_name" />
    <inputs-block :form="form" />
    <AstroButton
      :disabled="!valid"
      :title="$t('createMap.action')"
      :loading="submitInProgress"
      @click="submit"
      class="map-form__submit-btn"
    />
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { ChartType } from 'astro-chart';
import { METRIC_NAMES, sendMetrics } from '@/utils/metrics';
import AstroButton from '@/elements/components/AstroButton';
import InputsBlock from './InputsBlock';
import { HOUSE_SYSTEM_TYPE } from '@/types';
import textField from '@/elements/components/TextField';

const BIRTH_PLACE_TYPE_METRICS_MAP = {
  name: METRIC_NAMES.CHART_CREATED_WITH_SEARCH,
  map: METRIC_NAMES.CHART_CREATED_WITH_MAP,
  coords: METRIC_NAMES.CHART_CREATED_WITH_COORDS,
};

export default {
  name: 'NatalForm',
  components: { AstroButton, textField, InputsBlock },
  props: {
    data: {
      type: Object,
      default: null,
    },
    mapName: {
      type: String,
      default: '',
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      name: this.mapName,
      defaultData: {
        equalHouse: 'Равнодомная от Asc',
        cosmogram: false,
        birthDate: null,
        birthTime: null,
        birthPlace: null,
        birthGMT: '',
        birthLong: '',
        birthLat: '',
        birthPlaceType: 'name',
        autoGmt: true,
        autoHouse: true,
        houseSystem: 'Placidus',
      },
    };
  },
  beforeMount() {
    this.defaultData.houseSystem = this.settings.houseSystem;
  },
  computed: {
    ...mapState('ChartModule', ['houseSystem']),
    ...mapState('UserModule', ['settings']),
    birthPlaceIsValid() {
      return this.form.birthPlace || (this.form.birthLong && this.form.birthLat);
    },
    form() {
      return this.data ? Object.assign(this.defaultData, this.data) : this.defaultData;
    },
  },
  methods: {
    async submit() {
      await this.$refs.form.validate();
      if (!this.valid || !this.birthPlaceIsValid) {
        return;
      }

      this.form.equalHouse === 'Equal' ? (this.form.houseSystem = HOUSE_SYSTEM_TYPE.EQUAL) : '';
      this.form.equalHouse === 'EqualMC' ? (this.form.houseSystem = HOUSE_SYSTEM_TYPE.EQUAL_MC) : '';
      this.form.equalHouse === 'Koch' ? (this.form.houseSystem = HOUSE_SYSTEM_TYPE.KOCH) : '';
      this.form.equalHouse === 'Placidus' ? (this.form.houseSystem = HOUSE_SYSTEM_TYPE.PLACIDUS) : '';

      if (this.form.cosmogram) {
        this.form.birthTime = this.getCurrentTime();
      }

      sendMetrics(BIRTH_PLACE_TYPE_METRICS_MAP[this.form.birthPlaceType]);

      delete this.form.birthPlaceType;
      delete this.form.equalHouse;

      this.form.houseSystem === 'auto' ? (this.form.houseSystem = 'Placidus') : this.form.houseSystem;

      this.$emit('submit', { map1: this.form, chartType: ChartType.NATAL, mapName: this.name });
    },
    getCurrentTime() {
      const now = new Date();
      const currentTime = now.toLocaleTimeString('ru-RU');
      return currentTime;
    },
  },
};
</script>

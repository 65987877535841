<template>
  <div class="inputs-block">
    <div class="inputs-block__flex">
      <!--      <input-dates
        :label="birthDatePlaceholder"
        :value.sync="form.birthDate"
        :mask="maskDate"
        type="text"
        class="required"
        :class="{ placeholder: form.birthDate === null || form.birthDate === '' }"
        :required="true"
        :icon-name="'event'"
        @change="onBirthDateChange"
      />-->
      <!--      <input-dates
        :label="birthTimePlaceholder"
        :value.sync="form.birthTime"
        :mask="maskTime"
        type="text"
        class="required"
        :class="{ placeholder: form.birthTime === null || form.birthTime === '' }"
        :required="true"
        :icon-name="'schedule'"
        @change="onBirthTimeChange"
      />-->

      <text-field
        :label="birthDatePlaceholder"
        :placeholder="'dd.mm.yyyy'"
        :rules="birthDateRules"
        append-icon="event"
        v-model="form.birthDate"
        type="text"
        v-mask="'##.##.####'"
        class="required"
        :required="true"
        @change="onBirthDateChange"
      />

      <text-field
        :label="birthTimePlaceholder"
        :placeholder="currentTimePlaceholder"
        append-icon="schedule"
        :rules="birthTimeRules"
        v-model="form.birthTime"
        v-mask="'##:##:##'"
        type="text"
        :step="1"
        class="required"
        :required="true"
        @change="onBirthTimeChange"
        @keyup="form.cosmogram = false"
      />
    </div>
    <div v-if="showPlaceInputs" class="birth_type-inputs">
      <SearchBirthPlace
        :label="$t('createMap.birthPlace')"
        :required="true"
        :form="form"
        @change="onBirthPlaceChange"
      />
    </div>
    <label class="gmt-selection-label">
      <span>{{ $t('createMap.timeZone') }}</span>
      <v-select
        class="gmt-select"
        v-model="form.birthGMT"
        :items="gmtList"
        value.sync="value"
        item-value="value"
        item-text="title"
        label="GMT (+0)"
        single-line
        :loading="gmtLoading"
        @change="onGmtChange"
      >
      </v-select>
    </label>
    <label class="map_types">
      <span> {{ $t('pageBase.mapType') }}</span>
      <div class="map_types__fields">
        <v-select
          class="mapTypeSelect"
          v-model="form.houseSystem"
          :items="houseSystems"
          value="value"
          item-value="value"
          item-text="title"
          :label="form.houseSystem"
          single-line
          @change="onHouseChange"
        >
        </v-select>
        <check-box class="checkbox cosmogram" :input-value="form.cosmogram" @change="(val) => (form.cosmogram = val)">
          {{ $t('cosmogramm') }}
        </check-box>
      </div>
    </label>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import TextField from '@/elements/components/TextField';
import CheckBox from '@/elements/components/check-box';
import SearchBirthPlace from '@/elements/pages/natal-map/SearchBirthPlace';
import { TARIFF_PLAN, HOUSE_SYSTEM_TYPE, HOUSE_SYSTEM_TRANSLATION } from '@/types';
/*import InputDates from '@/elements/components/inputs/InputDates';*/
import { IMask } from 'vue-imask';
import { i18n } from '@/plugins/i18n';
export default {
  name: 'InputsBlock',
  components: { SearchBirthPlace, CheckBox, TextField },
  props: {
    form: {
      type: Object,
      required: true,
    },
    /*birthDatePlaceholder: {
      type: String,
      default: i18n.locale === 'ru' ? 'Дата рождения' : 'Geburtsdatum',
    },
    birthTimePlaceholder: {
      type: String,
      default: i18n.locale === 'ru' ? 'Время рождения' : 'Geburtszeit',
    },*/
    showPlaceInputs: {
      type: Boolean,
      default: true,
    },
    firstMapBirthPlace: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maskDate: {
        mask: 'd.m.y',
        lazy: false,
        autofix: true,
        blocks: {
          d: { mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2 },
          m: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2 },
          y: { mask: IMask.MaskedRange, placeholderChar: 'y', from: 1900, to: 2999, maxLength: 4 },
        },
      },
      maskTime: {
        mask: 'h:m:s',
        lazy: false,
        autofix: true,
        blocks: {
          h: { mask: IMask.MaskedRange, placeholderChar: 'h', from: 1, to: 23, maxLength: 2 },
          m: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 59, maxLength: 2 },
          s: { mask: IMask.MaskedRange, placeholderChar: 's', from: 1, to: 59, maxLength: 2 },
        },
      },
      /* houseSystems: [
        {
          title: 'Auto',
          value: 'auto',
        },
        {
          title: 'Плацидус',
          value: 'Placidus',
        },
        {
          title: 'Кох',
          value: 'Koch',
        },
        {
          title: 'Равнодомная от Asc',
          value: 'Equal',
        },
        {
          title: 'Равнодомная от MC',
          value: 'EqualMC',
        },
      ],*/
      gmtListSelected: 'GMT (+0)',
      gmtList: [
        {
          title: 'GMT (-12)',
          value: -12,
        },
        {
          title: 'GMT (-11)',
          value: -11,
        },
        {
          title: 'GMT (-10)',
          value: -10,
        },
        {
          title: 'GMT (-9)',
          value: -9,
        },
        {
          title: 'GMT (-8)',
          value: -8,
        },
        {
          title: 'GMT (-7)',
          value: -7,
        },
        {
          title: 'GMT (-6)',
          value: -6,
        },
        {
          title: 'GMT (-5)',
          value: -5,
        },
        {
          title: 'GMT (-4)',
          value: -4,
        },
        {
          title: 'GMT (-3)',
          value: -3,
        },
        {
          title: 'GMT (-2)',
          value: -2,
        },
        {
          title: 'GMT (-1)',
          value: -1,
        },
        {
          title: 'Auto',
          value: 0,
        },
        {
          title: 'GMT (+1)',
          value: 1,
        },
        {
          title: 'GMT (+2)',
          value: 2,
        },
        {
          title: 'GMT (+3)',
          value: 3,
        },
        {
          title: 'GMT (+4)',
          value: 4,
        },
        {
          title: 'GMT (+5)',
          value: 5,
        },
        {
          title: 'GMT (+6)',
          value: 6,
        },
        {
          title: 'GMT (+7)',
          value: 7,
        },
        {
          title: 'GMT (+8)',
          value: 8,
        },
        {
          title: 'GMT (+9)',
          value: 9,
        },
        {
          title: 'GMT (+10)',
          value: 10,
        },
        {
          title: 'GMT (+11)',
          value: 11,
        },
        {
          title: 'GMT (+12)',
          value: 12,
        },
      ],
      gmtLoading: false,
      cities: [],
      isLoading: false,
      search: null,
      requiredRules: [(v) => !!v || 'Поле должно быть заполнено'],

      TARIFF_PLAN,
      HOUSE_SYSTEM_TYPE,
      HOUSE_SYSTEM_TRANSLATION,
    };
  },
  mounted() {
    this.form.equalHouse = Object.values(HOUSE_SYSTEM_TYPE)[0];
    this.$nextTick(() => {
      if (this.form.birthPlaceType === 'name' && this.form.birthPlace) {
        this.cities.push(this.form.birthPlace);
        this.$forceUpdate();
      }
    });
  },
  watch: {
    search(query) {
      if (query && (!this.form.birthPlace || this.form.birthPlace !== query)) {
        this.processSearch();
      }
    },
    'form.cosmogram'(value) {
      if (!value) {
        return;
      }
      const now = new Date();
      const currentTime = now.toLocaleTimeString('ru-RU');
      this.form.birthTime = currentTime;
    },
    firstMapBirthPlace(value) {
      if (this.showPlaceInputs) return;
      this.onBirthPlaceChange(value);
    },
  },
  methods: {
    ...mapActions('ChartModule', ['getPlaceTimezone', 'getPlaceInfo']),
    async onBirthPlaceChange(placeName) {
      if (!placeName) {
        return;
      }

      const { birthDate, birthTime, houseSystem, autoHouse } = this.form;
      const date = birthDate && birthTime && moment.utc(`${birthDate} ${birthTime}`, 'DD.MM.YYYY HH:mm:ss').format();
      const now = moment.utc().format();

      const time = date || now;

      if (moment(time).isValid()) {
        await this.setTimeShift({
          time,
          placeName,
          houseSystemConfig: {
            auto: autoHouse,
            houseSystem: houseSystem,
          },
        });
      }
    },
    async setTimeShift(data) {
      try {
        this.gmtLoading = true;
        let response = await this.getPlaceInfo(data);
        this.form.birthGMT = response.timeShift;
        this.form.houseSystem = response.houseSystem;
        this.form.autoGmt = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.gmtLoading = false;
      }
    },
    async onBirthDateChange(birthDate) {
      const { birthTime, birthPlace: placeName, houseSystem, autoHouse } = this.form;
      const birthPlace = this.showPlaceInputs ? placeName : this.firstMapBirthPlace;

      if (!birthDate || !birthTime || !birthPlace) {
        return;
      }
      const time = moment.utc(`${birthDate} ${birthTime}`, 'DD.MM.YYYY HH:mm:ss').format();
      if (moment(time).isValid()) {
        await this.setTimeShift({
          time,
          placeName: birthPlace,
          houseSystemConfig: {
            auto: autoHouse,
            houseSystem: houseSystem,
          },
        });
      }
    },
    async onBirthTimeChange(birthTime) {
      const { birthDate, birthPlace: placeName, houseSystem, autoHouse } = this.form;
      const birthPlace = this.showPlaceInputs ? placeName : this.firstMapBirthPlace;

      if (!birthDate || !birthTime || !birthPlace) {
        return;
      }

      const time = moment.utc(`${birthDate} ${birthTime}`, 'DD.MM.YYYY HH:mm:ss').format();
      if (moment(time).isValid()) {
        await this.setTimeShift({
          time,
          placeName: birthPlace,
          houseSystemConfig: {
            auto: autoHouse,
            houseSystem: houseSystem,
          },
        });
      }
    },
    onGmtChange(gmt) {
      this.form.autoGmt = !gmt;
    },
    onHouseChange(house) {
      if (this.form.houseSystem === 'auto') {
        return (this.form.autoHouse = true);
      } else {
        this.form.autoHouse = !house;
      }
    },
  },
  computed: {
    birthDate() {
      console.log(this.form.birthDate);
      return this.form.birthDate;
    },
    birthDateRules() {
      return [
        (v) =>
          !!v || (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        (v) => moment(v, 'DD.MM.YYYY').isValid() || this.$i18n.t('rules.currentDate'),
        /*(i18n.locale === 'en' ? moment(v, 'DD.MM.YYYY').isValid() : moment(v, 'MM.DD.YYYY').isValid()) ||
          (i18n.locale === 'en' ? 'Введите корректную дату' : 'Das Feld muss ausgefüllt werden'),*/
      ];
    },
    birthTimeRules() {
      return [
        (v) =>
          !!v || (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        (v) => moment(v, 'HH:mm:ss').isValid() || this.$i18n.t('rules.currentDate'),
      ];
    },
    currentDatePlaceholder() {
      /*return i18n.locale === 'en' ? 'dd.mm.yyyy' : 'mm.dd.yyyy';*/
      return 'dd.mm.yyyy';
    },
    currentTimePlaceholder() {
      return 'hh:mm:ss';
    },
    birthDatePlaceholder() {
      return i18n.locale === 'en' ? 'Birth date' : 'Geburtsdatum';
    },
    birthTimePlaceholder() {
      return i18n.locale === 'en' ? 'Birth time' : 'Geburtszeit';
    },
    houseSystems() {
      return [
        {
          title: 'Auto',
          value: 'auto',
        },
        {
          title: this.$t('Placidus'),
          value: 'Placidus',
        },
        {
          title: this.$t('Koch'),
          value: 'Koch',
        },
        {
          title: this.$t('Equal'),
          value: 'Equal',
        },
        {
          title: this.$t('EqualMC'),
          value: 'EqualMC',
        },
      ];
    },
  },
};
</script>
<style lang="sass">
.map_name
  .v-input
    max-width: base-unit(280)
    input
      &::placeholder
        color: #666666

.inputs-block
  &__flex
    display: flex
    flex-direction: column
    max-width: base-unit(150)
    +small-devices
      flex-direction: row
      column-gap: base-unit(10)
      max-width: base-unit(310)
    .text-field-label
      sup
        font-size: base-unit(16)
        top: 0

  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr
    gap: 0 base-unit(5)
    +medium-devices
      gap: 0 base-unit(20)

  &-item
    display: flex
    flex-direction: column
    span
      font-size: base-unit(12)
      color: grey-color('75')

  &__el--no-margin
    margin-top: 0 !important

  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label,
  .v-input--radio-group__input .v-radio > .v-label
    flex:	0 1 auto

  .checkbox
    .v-label
      font-size: base-unit(14) !important
      +medium-devices
        font-size: base-unit(16) !important

  .required
    label
      &::after
        content: "*"
        display: block
        color: #d64040

  .map
    width: 100%
    height: 300px
    color: #d64040

.v-autocomplete-cities-list-item
  .powered-by-google
    display: none
  &:last-child
    position: relative
    .powered-by-google
      display: block
      position: absolute
      right: base-unit(5)
      bottom: base-unit(-5)

.inputs-block__radio-group-label
  font-weight: 700
  font-size: base-unit(16)
  color: #666666
  margin-bottom: base-unit(5)
  +medium-devices
    font-size: base-unit(18)


.birth_type-inputs
  max-width: base-unit(480)


.mapTypeSelect
  margin-right: base-unit(30)
  .v-input__slot
    padding-left: base-unit(16)
    border: 1px solid grey-color('75') !important
    border-radius: base-unit(4)
    &:before
      border-color: transparent !important
  .v-text-field
    padding-top: 0
    margin-top: 0
.map_types
  display: flex
  flex-direction: column
  justify-content: space-between
  max-width: base-unit(250)
  +medium-devices
    max-width: base-unit(380)
  &__fields
    display: flex
    flex-direction: column
    +medium-devices
      flex-direction: row
      align-items: center
  .cosmogram
    .v-input--selection-controls__ripple
      display: none

  span
    color: grey-color('500')
  .v-input__slot
    align-items: center
  .v-input--selection-controls__input
    margin-bottom: base-unit(4)


.gmt-selection-label
  max-width: base-unit(140)
  width: 100%
  margin-bottom: base-unit(13)
  span
    display: inline-block
    line-height: base-unit(19)
    margin-bottom: base-unit(8)
    color: grey-color('500')

.gmt-select
  margin-top: 0
  padding-top: 0
  max-width: base-unit(140)
  .v-input__slot
    border: 1px solid grey-color('75') !important
    border-radius: base-unit(4)
    &:before
      border-color: transparent !important
  .v-select__slot
    text-transform: none
    font-weight: 400
    color: grey-color('400')
    opacity: 0.7
    padding-left: base-unit(16)
  .v-input__control
    min-height: auto
    margin-top: 0
    max-width: base-unit(140)
    .v-input__slot
      box-shadow: none !important
  .v-label
    padding-left: 12px
</style>

<template>
  <page-template :title="pageTitle">
    <div class="chart-page-content">
      <div class="tabs-container">
        <v-tabs show-arrows v-model="currentMapOption">
          <v-tabs-slider :color="'#167EA2'" class="v-slide-slider"></v-tabs-slider>
          <v-tab
            class="v-slide-tab"
            active-class="v-slide-tab-active"
            v-for="option in mapOptions"
            :key="option.id"
            @click="currentMapOption = option.id"
          >
            {{ $t(option.label) }}
          </v-tab>
          <v-tab class="prognosys-tab v-slide-tab" active-class="v-slide-tab-active">
            <v-select
              class="prognosys-select"
              v-model="currentPrognosysType"
              :items="prognosysItems"
              value.sync="chartType"
              item-value="chartType"
              item-text="label"
              label="Прогностика"
              solo
              single-line
            ></v-select>
          </v-tab>
        </v-tabs>
      </div>

      <natal-form
        v-if="currentMapOption === 0"
        :map-name="mapName"
        :data="mapData.map1"
        :submit-in-progress="submitInProgress"
        v-on="$listeners"
      />
      <synastry-form
        v-if="currentMapOption === 1"
        :map-name="mapName"
        :data="mapData"
        :submit-in-progress="submitInProgress"
        v-on="$listeners"
      />
      <prognosys-form
        v-if="currentMapOption === 2"
        v-on="$listeners"
        :map-type="currentPrognosysType"
        :submit-in-progress="submitInProgress"
        :map-name="mapName"
        :data="mapData"
      />
    </div>
  </page-template>
</template>
<script>
import { ChartType } from 'astro-chart';

import { PageTemplate } from '@/elements/components/page';
import NatalForm from './NatalForm';
import SynastryForm from './SynastryForm';
import PrognosysForm from './PrognosysForm';

export default {
  name: 'PageContent',
  components: {
    NatalForm,
    PrognosysForm,
    SynastryForm,
    PageTemplate,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    mapOption: {
      type: Number,
      default: 0,
    },
    prognosysMapType: {
      type: String,
      default: ChartType.SOLAR,
    },
    mapData: {
      type: Object,
      default: () => ({
        map1: null,
        map2: null,
      }),
    },
    mapName: {
      type: String,
      default: '',
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /*prognosysItems: [
        {
          chartType: ChartType.SOLAR,
          label: this.$t('prognosis.SOLAR'),
        },
        {
          chartType: ChartType.TRANSIT,
          label: this.$t('prognosis.TRANSIT'),
        },
        {
          chartType: ChartType.DIRECTION,
          label: this.$t('prognosis.DIRECTION'),
        },
        {
          chartType: ChartType.PROGRESSION,
          label: this.$t('prognosis.PROGRESSION'),
        },
      ],*/
      mapOptions: [
        {
          id: 'natal',
          label: 'NATAL',
        },
        {
          id: 'synastry',
          label: 'SYNASTRY',
        },
      ],
      currentMapOption: this.mapOption,
      currentPrognosysType: ChartType.SOLAR,
    };
  },
  watch: {
    prognosysMapType(value) {
      this.currentPrognosysType = value;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.currentMapOption = this.mapOption;
    });
  },

  computed: {
    prognosysItems() {
      return [
        {
          chartType: ChartType.SOLAR,
          label: this.$t('prognosis.SOLAR'),
        },
        {
          chartType: ChartType.TRANSIT,
          label: this.$t('prognosis.TRANSIT'),
        },
        {
          chartType: ChartType.DIRECTION,
          label: this.$t('prognosis.DIRECTION'),
        },
        {
          chartType: ChartType.PROGRESSION,
          label: this.$t('prognosis.PROGRESSION'),
        },
      ];
    },
  },
};
</script>
<style lang="sass" scoped>
.tabs-container
  display: flex
  align-items: center
  margin-bottom: base-unit(30)
  border-bottom: 1px solid #F2F2F2
  .prognosys-tab
    max-width: base-unit(250)
    width: 100%
    padding: 0
  .v-slide-slider
    width: auto
    max-width: 100%
  .v-slide-tab
    font-size: base-unit(16)
    font-weight: 400
    color: blue-color('primary')
    &:not(.v-tab--active)
      color: grey-color('500') !important
    &:last-child
      margin-right: 0

.prognosys-select
  max-width: base-unit(250)
  width: 100%
  ::v-deep .v-select__slot
    text-transform: none
    font-weight: 400
    .v-select__selections
      input
        width: base-unit(2)
    .v-select__selection--comma
      max-width: 100%
      padding: 0 base-unit(5)
      color: grey-color('500') !important
  ::v-deep .v-input__control
    min-height: auto
    background: transparent
  ::v-deep .v-input__slot
      margin-bottom: 0
      padding: 0 !important
      box-shadow: none !important
      background: transparent !important
  ::v-deep .v-text-field__details
    display: none

.chart-page-content
  width: 100%
  ::v-deep .map-form__submit-btn
    margin: base-unit(30) 0 0
    width: base-unit(166)

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  color: grey-color('500') !important
</style>

<template>
  <v-form ref="form" v-model="valid" lazy-validation class="map-form">
    <div class="double-inputs-block">
      <div>
        <h3>{{ firstMapTitle }}</h3>
        <inputs-block :form="map1" />
      </div>
      <div class="separator"></div>
      <div>
        <h3>{{ secondMapTitle }}</h3>
        <inputs-block
          :form="map2"
          :first-map-birth-place="map1.birthPlace"
          :showPlaceInputs="secondMapShowPlaceInputs"
          :birthTimePlaceholder="secondMapBirthTimePlaceholder"
          :birthDatePlaceholder="secondMapBirthDatePlaceholder"
        />
      </div>
    </div>
    <AstroButton
      :disabled="!valid"
      :title="$t('createMap.action')"
      :loading="submitInProgress"
      @click="submit"
      class="map-form__submit-btn"
    />
  </v-form>
</template>
<script>
import { METRIC_NAMES, sendMetrics } from '@/utils/metrics';
import AstroButton from '@/elements/components/AstroButton';
import InputsBlock from './InputsBlock';
import { HOUSE_SYSTEM_TYPE } from '@/types';
import { mapState } from 'vuex';
import { i18n } from '@/plugins/i18n';

const BIRTH_PLACE_TYPE_METRICS_MAP = {
  name: METRIC_NAMES.CHART_CREATED_WITH_SEARCH,
  map: METRIC_NAMES.CHART_CREATED_WITH_MAP,
  coords: METRIC_NAMES.CHART_CREATED_WITH_COORDS,
};

export default {
  name: 'DoubleInputsBlock',
  components: { AstroButton, InputsBlock },
  props: {
    chartType: {
      type: String,
      required: true,
    },
    secondMapShowPlaceInputs: {
      type: Boolean,
      default: true,
    },
    secondMapBirthTimePlaceholder: {
      type: String,
    },
    secondMapBirthDatePlaceholder: {
      type: String,
    },
    firstMapTitle: {
      type: String,
      default: i18n.locale === 'en' ? 'Natal chart' : 'Natal Karte',
    },
    secondMapTitle: {
      type: String,
      default: i18n.locale === 'en' ? 'Projection' : 'Prognose',
    },
    data: {
      type: Object,
      default: null,
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map1DefaultData: {
        equalHouse: 'Равнодомная от Asc',
        cosmogram: false,
        birthDate: null,
        birthTime: null,
        birthPlace: null,
        birthLong: '',
        birthLat: '',
        birthGMT: '',
        birthPlaceType: 'name',
        autoHouse: true,
        houseSystem: 'Placidus',
      },
      map2DefaultData: {
        equalHouse: 'Равнодомная от Asc',
        cosmogram: false,
        birthDate: null,
        birthTime: null,
        birthPlace: null,
        birthLong: '',
        birthLat: '',
        birthGMT: '',
        birthPlaceType: 'name',
        autoHouse: true,
        houseSystem: 'Placidus',
      },
      valid: false,
    };
  },
  beforeMount() {
    this.map1DefaultData.houseSystem = this.settings.houseSystem;
    this.map2DefaultData.houseSystem = this.settings.houseSystem;
  },
  computed: {
    ...mapState('UserModule', ['settings']),
    birthPlaceIsValid() {
      const map1Valid = this.map1.birthPlace || (this.map1.birthLong && this.map1.birthLat);
      const map2Valid = this.secondMapShowPlaceInputs
        ? this.map2.birthPlace || (this.map2.birthLong && this.map2.birthLat)
        : true;

      return map1Valid && map2Valid;
    },
    map1() {
      return this.data && this.data.map1 ? Object.assign(this.map1DefaultData, this.data.map1) : this.map1DefaultData;
    },
    map2() {
      return this.data && this.data.map2 ? Object.assign(this.map2DefaultData, this.data.map2) : this.map2DefaultData;
    },
  },
  methods: {
    async submit() {
      await this.$refs.form.validate();

      if (!this.valid || !this.birthPlaceIsValid) {
        return;
      }
      const map1 = this.buildMap(this.map1);
      const map2 = this.buildMap(this.map2);

      this.$emit('submit', {
        map1,
        map2,
        chartType: this.chartType,
      });
    },
    buildMap(data) {
      const result = { ...data };

      data.equalHouse === 'Equal' ? (result.mapType = HOUSE_SYSTEM_TYPE.EQUAL) : '';
      data.equalHouse === 'EqualMC' ? (result.mapType = HOUSE_SYSTEM_TYPE.EQUAL_MC) : '';
      data.equalHouse === 'Koch' ? (result.mapType = HOUSE_SYSTEM_TYPE.KOCH) : '';
      data.equalHouse === 'Placidus' ? (result.mapType = HOUSE_SYSTEM_TYPE.PLACIDUS) : '';

      console.log(data);
      data.houseSystem === 'auto' ? (data.houseSystem = 'Placidus') : data.houseSystem;

      if (data.cosmogram) {
        result.birthTime = this.getCurrentTime();
      }

      sendMetrics(BIRTH_PLACE_TYPE_METRICS_MAP[data.birthPlaceType]);

      delete result.birthPlaceType;
      delete result.equalHouse;

      return result;
    },
    getCurrentTime() {
      const now = new Date();
      const currentTime = now.toLocaleTimeString('ru-RU');
      return currentTime;
    },
  },
};
</script>
<style lang="sass" scoped>
.double-inputs-block
  +high-resolution-devices
    display: grid
    grid-template-columns: 1fr base-unit(1) 1fr
    grid-template-rows: 1fr
    gap: 0 base-unit(50)
  h3
    color: blue-color('primary')
    font-size: base-unit(16)
    margin-bottom: base-unit(20)
    +medium-devices
      font-size: base-unit(20)

.separator
  background: grey-color('10')
  height: base-unit(1)
  width: 100%
  margin: base-unit(30) 0
  +high-resolution-devices
    margin: 0
    height: auto
</style>
